<template>
  <v-app-bar class="navbar" v-if="showAppbar && isLogged()" app dark>

    <v-spacer></v-spacer>
      <ul class="navbar__flags">
        <li
          v-for="(language, index) in languages()"
          :key="index"
          class="flag"
          @click="selectLanguage(language, $event)"
        >
          <country-flag
            class="flag-icon"
            :class="isActive(language) ? 'active' : ''"
            :country="language.code"
            size="normal"
          />
        </li>
      </ul>
        <v-btn class="navbar__v-btn" elevation="0">
      <span class="mr-2">Clienti in Cloud</span>
    </v-btn>
        <v-btn class="navbar__v-btn" elevation="0">
      <span class="mr-2">CRM</span>
    </v-btn>
    <v-btn class="navbar__v-btn-add-event" elevation="0">
      <v-icon>mdi-plus-circle</v-icon>
      <span class="navbar__add-event-label">Aggiungi Evento</span>
    </v-btn>
    <DropdownMenu />
  </v-app-bar>
</template>

<script>
import { Utility } from "@/lib/Utility";
import Languages from "@/mixins/languages.js";
import Component, { mixins } from "vue-class-component";
import { localStorageService } from "@/services/localStorageService.js";
import Logo from "@/components/logo";
import CountryFlag from "vue-country-flag";
import Notification from "@/components/dom/notification";
import DropdownMenu from "@/components/navbar/DropdownMenu";

@Component({
  components: {
    Logo,
    CountryFlag,
    Notification,
    DropdownMenu
  },
})
export default class Navbar extends mixins(Languages) {

  get showAppbar() {
    return this.$route.meta?.showAppbar;
  }

  recomputeFlags() {
    this.recomputeFlagId = Utility.uid("ab");
  }

  isLogged() {
    return !!localStorageService.getLoggedUser();
  }

  selectLanguage(language, $event) {
    $event.preventDefault();
    this.setCurrLang(language);
  }

  isActive(language) {
    const lang = this.currLang();
    return language.id === lang?.id;
  }
}
</script>

<style lang="scss" scoped>
.navbar {
  background-color: #F6F8FA !important;
  &__v-btn-add-event {
    background-color: #3EFDBF !important;
    color: #000 !important;
    border-radius: 3px;
    margin-left: 10px;
  }

  &__v-btn {
    background-color: #EBEFFA !important;
    color: #2B6EFB !important;
    border-radius: 3px;
    margin-left: 10px;
    border: 1px solid #B2C9FA;
  }

  &__add-event-label {
    padding-left: 10px;
  }

  &__flags {
    display: flex;
  }
}
</style>