export const state = {
  currentUser: null,
  impersonatedUser: null
};

export const mutations = {
  SET_CURRENT_USER(state, currentUser) {
    state.currentUser = currentUser;
  },
  RESET_CURRENT_USER(state) {
    state.currentUser = null;
  },
  SET_IMPERSONATED_USER(state, impersonatedUser) {
    state.impersonatedUser = impersonatedUser;
  },
  RESET_IMPERSONATED_USER(state) {
    state.impersonatedUser = null;
  }
};
