import Vue from "vue";
import Component from "vue-class-component";
import {
  languageService
} from "@/services/languageService.js";
import {
  localStorageService
} from "@/services/localStorageService.js";

@Component()
export default class Languages extends Vue {
  // computed

  // methods

  currLang() {
    const currLang = this.$store.state.languageStore?.currentLanguage;
    if (!currLang) {
      const lsCurrLang = localStorageService.getCurrentLang();
      if (lsCurrLang) {
        this.setCurrLang(lsCurrLang);
        return lsCurrLang;
      }
    }
    const langs = this.languages();
    if (!currLang && langs.length > 0) {
      this.setCurrLang(langs[0]);
      return langs[0];
    }
    return currLang;
  }

  get currentLanguage () {
    return this.currLang();
  }

  setCurrLang(lang) {
    this.$store.commit("SET_CURRENT_LANGUAGE", lang);
    return localStorageService.setCurrentLang(lang);
  }

  resetCurrLang() {
    this.$store.commit("RESET_CURRENT_LANGUAGE");
    return localStorageService.removetCurrentLang();
  }

  languages() {
    return (this.$store.state.languageStore.languages ?? []).map(l => {
      return {
        ...l,
        code: (l?.code === "en" ? "gb" : l?.code)
      };
    });
  }

  setLanguages(langs) {
    this.$store.commit("SET_LANGUAGES", langs);
  }

  resetLanguages() {
    this.$store.commit("RESET_LANGUAGES");
  }

  language(languageId) {
    if (!this.languages()) {
      return null;
    }
    const langs = this.languages();
    if (!langs) {
      return null;
    }
    const language = langs.filter(e => e.id == languageId);

    return language.length > 0 ?
      language[0] :
      null;
  }

  languageByCode(languageCode) {
    if (!this.languages()) {
      return null;
    }
    const langs = this.languages();
    if (!langs) {
      return null;
    }
    const language = langs.filter(e => e.code == languageCode);

    return language.length > 0 ?
      language[0] :
      null;
  }

  afterLanguagesInit() {
    // override this method if needed
  }

  isLanguagesInitialized() {
    return !(this.languages() === null || typeof this.languages() === "undefined" || this.languages().length === 0);
  }

  async mounted() {
    // if the store is already set, we avoid to re-fetch configs, languages, etc.
    // if even one of them is missing, we "refresh" all of them, just to be sure.
    if (this.isLanguagesInitialized() || Languages.prototype._initializing === true) {
      this.afterLanguagesInit();
      return;
    }
    Languages.prototype._initializing = true;
    const langs = await languageService.list();
    this.setLanguages(langs);
    this.afterLanguagesInit();
  }
}