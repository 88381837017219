<template>
 <svg xmlns="http://www.w3.org/2000/svg" class="logo" width="35" height="35" viewBox="0 0 49.85 49.85">
  <g id="Raggruppa_8" data-name="Raggruppa 8" transform="translate(-221.656 -230.528)">
    <g id="Raggruppa_4" data-name="Raggruppa 4" transform="translate(228.493 231.195)">
      <rect id="Rettangolo_1" data-name="Rettangolo 1" width="44.208" height="3.071" transform="matrix(0.398, -0.917, 0.917, 0.398, 0, 40.547)"  fill="#fff"/>
    </g>
    <g id="Raggruppa_5" data-name="Raggruppa 5" transform="translate(229.838 236.827)">
      <rect id="Rettangolo_2" data-name="Rettangolo 2" width="3.071" height="45.573" transform="translate(0 1.299) rotate(-25.013)"  fill="#fff"/>
    </g>
    <g id="Raggruppa_6" data-name="Raggruppa 6" transform="translate(221.656 230.528)">
      <path id="Tracciato_10" data-name="Tracciato 10" d="M416.668,280.378a24.925,24.925,0,1,1,24.925-24.925A24.953,24.953,0,0,1,416.668,280.378Zm0-47.29a22.366,22.366,0,1,0,22.366,22.366A22.391,22.391,0,0,0,416.668,233.087Z" transform="translate(-391.743 -230.528)"  fill="#fff"/>
    </g>
  </g>
</svg>
</template>
<script>
import Vue from "vue";
import Component from "vue-class-component";

@Component({
  props: {
    light: {
      type: Boolean,
      default: false
    }
  }
})
export default class Logo extends Vue {

}
</script>

<style lang="scss" scoped>
.logo {
  padding: 5px;
}
</style>