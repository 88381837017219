import { ApiHandler } from "@/lib/ApiHandler";

class LanguageService extends ApiHandler {
  constructor() {
    super("/api/languages");
  }

  async list() {
    return await this.get()
      .then(res => res.json())
      .then(res => res.result);
  }
}

export const languageService = new LanguageService();
