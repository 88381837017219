class StorageService {
  isWindowDefined() {
    return typeof window !== "undefined";
  }

  getLocal(name, defaultValue = null) {
    if (!this.isWindowDefined()) {
      return null;
    }

    const item = window.localStorage.getItem(name);
    try {
      return item ? JSON.parse(item) : defaultValue;
    } catch (_) {
      return defaultValue;
    }
  }

  setLocal(name, value) {
    if (!this.isWindowDefined()) {
      return;
    }
    window.localStorage.setItem(name, JSON.stringify(value));
  }

  removeLocal(name) {
    if (!this.isWindowDefined()) {
      return;
    }

    window.localStorage.removeItem(name);
  }

  getSession(name) {
    if (!this.isWindowDefined()) {
      return;
    }

    const item = window.sessionStorage.getItem(name);
    return item ? JSON.parse(item) : null;
  }

  setSession(name, value) {
    if (!this.isWindowDefined()) {
      return;
    }

    window.sessionStorage.setItem(name, JSON.stringify(value));
  }

  removeSession(name) {
    if (!this.isWindowDefined()) {
      return;
    }

    window.sessionStorage.removeItem(name);
  }
}

export const storageService = new StorageService();
