import { localStorageService } from "@/services/localStorageService.js";
import _env from "@/plugins/env";

export class Utility {
  static getFilePath(file) {
    return file ? _env("NUXT_ENV_API_BASE_URL") + "/Files/" + file : null;
  }

  static getFileExtension(url) {
    return url
      ?.split(/[#?]/)[0]
      .split(".")
      .pop()
      .trim();
  }

  static isAnImage(url) {
    const extension = this.getFileExtension(url);
    return (
      extension === "jpg" ||
      extension === "jpeg" ||
      extension === "png" ||
      extension === "svg"
    );
  }

  static getIconFromExtension(url) {
    switch (this.getFileExtension(url)) {
      case "pdf":
        return "Pdf";
      case "xls":
      case "xlsx":
        return "Xls";
      case "ppt":
        return "Ppt";
      case "doc":
      case "docx":
        return "Doc";
      case "zip":
        return "Zip";
      default:
        return null;
    }

    // let ext = uri.split('.');
    // ext = ext[ext.length - 1].toLowerCase();
    // if (![ "pdf", "doc", "xls", "ppt", "zip" ].includes(ext)) {
    //   // TODO: default generic file icon
    //   return "file-folder";
    // }
    // return ext;
  }

  //  TODO Dare la possibilià di evidenziare i link inseriti da cms
  static getKeyPress(callback) {
    window.addEventListener("keypress", e => {
      if (e.code === "KeyD") {
        if (callback) {
          callback();
        }
      }
    });
  }

  /**
   * Retrieves a value from an object passing the key in dot-notation.
   *
   * i.e.:
   * const config = {
   *    platform: {
   *      title: "test"
   *    }
   * }
   *
   * config("platform.title", config); // returns test
   *
   * @param {string} key to search in the config object
   * @param {object} configs object
   */
  static extractFromObj(key, obj) {
    if (!obj) {
      return null;
    }
    const keys = key.split(".", 2);
    key = keys[0];
    if (!obj[key]) {
      return null;
    }
    if (keys.length > 1) {
      return this.extractFromObj(keys[1], obj[key]);
    }
    return obj[key];
  }

  static readableDate(value) {
    const options = { weekday: "long", month: "long", day: "numeric" };
    return new Date(value).toLocaleDateString(
      localStorageService.getCurrentLang(),
      options
    );
  }

  static formatDate(value) {
    const dateObj = new Date(value);
    const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
    const day = dateObj
      .getDate()
      .toString()
      .padStart(2, "0");
    return `${dateObj.getFullYear()}-${month}-${day}`;
  }

  static formatTime(value) {
    const dt = new Date(value);
    return `${dt.getHours()}:${dt.getMinutes()}`;
  }

  static clone(obj) {
    return JSON.parse(JSON.stringify(obj));
  }

  // static counter for uids
  static _uid = new Date().getTime();

  /**
   * Returns a unique id (local to the client)
   * @param {string} prefix uid prefix
   * @param {number} length lenght of the wanted id (default: 32)
   */
  static uid(prefix, length = 32) {
    // just to be sure, we concatenate a random string to a unique counter
    const id =
      (prefix || "") +
      this._uid++ +
      "-" +
      Math.random()
        .toString(36)
        .substr(2, 9);
    return id.substr(0, length).padStart(length, "0");
  }
}
