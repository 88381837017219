export const state = {
  events: [],
};

export const mutations = {
  SET_EVENTS(state, events) {
    state.events = events;
  },
  RESET_EVENTS(state) {
    state.events = [];
  },
};
