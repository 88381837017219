<template>
  <div class="sidebar">
    <router-link to="/">
      <span class="sidebar__wrapper-logo">
        <Logo />
      </span>
    </router-link>
  </div>
</template>

<script>
import Base from "@/mixins/base.js";
import Logo from "@/components/logo";
import Component from "vue-class-component";

@Component({
  components: {
    Logo,
  },
})
export default class Sidebar extends Base {

}
</script>

<style lang="scss" scoped>
.sidebar {
  background-color: #fa1857;
  height: 100vh;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 9999;
  padding: 5px;

  &__wrapper-logo {
    padding: 8px;
  }
}
</style>
