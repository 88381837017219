import Vue from "vue";
import VueRouter from "vue-router";
import authMiddleware from "../middleware/authMiddleware";
import _env from "@/plugins/env";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/auth/login/Login.vue")
  },
  {
    path: "/live-session/:lang/:id",
    name: "LiveSession",
    component: () => import("../views/liveSessions/LiveSessionDetail.vue"),
    beforeEnter: (_to, _from, next) => {
      next();
      authMiddleware.isLoggedUser() ? next() : router.push("/login");
    }
  },
  {
    path: "/live-session/zoom",
    name: "LiveSessionZoom",
    component: () => import("../views/liveSessions/zoom.vue"),
    beforeEnter: (_to, _from, next) => {
      next();
      authMiddleware.isLoggedUser() ? next() : router.push("/login");
    }
  },
  {
    path: "/",
    name: "Home",
    component: () => import("../views/home/Home.vue"),
    beforeEnter: (_to, _from, next) => {
      next();
      authMiddleware.isLoggedUser() ? next() : router.push("/login");
    },
    meta: {
      showAppbar: true
    }
  },
  {
    path: "/forgot-password",
    name: "Forgot-password",
    component: () => import("../views/auth/login/ForgotPassword.vue")
  },
  {
    path: "/reset-password",
    name: "reset-password",
    component: () => import("../views/auth/login/ResetPassword.vue")
  },
  {
    path: "/liveSessionDefaultUser",
    name: "LiveSessionDefaultUser",
    component: () => import("../views/liveSessionDefaultUser/LiveSessionDefaultUser.vue"),
    beforeEnter: (_to, _from, next) => {
      next();
      authMiddleware.isLoggedUser() ? next() : router.push("/login");
    },
    meta: {
      showAppbar: true
    }
  }
];

const router = new VueRouter({
  mode: "history",
  base: _env("BASE_URL"),
  routes
});

export default router;
