import Component, { mixins } from "vue-class-component";
import Events from "@/mixins/events.js";
import Languages from "@/mixins/languages.js";
import { localStorageService } from "@/services/localStorageService.js";

@Component()
export default class Base extends mixins(Languages, Events) {
  _depsInitialized = 0;

  // computed

  // methods
  eventDefaultLanguage(eventId) {
    const event = this.event(eventId);
    if (!event) {
      return null;
    }
    return this.language(event.defaultLanguageId) ?? null;
  }

  getLocalizedField(localizations, languageCode, eventId, fieldName) {
    let language = this.languageByCode(languageCode);
    const defaultLanguage = this.eventDefaultLanguage(eventId);
    const clonedLocs = JSON.parse(JSON.stringify(localizations));
    if (!language) {
      language = defaultLanguage;
    }
    let loc = clonedLocs.find(l => {
      return l.idLanguage == language.id;
    });
    if (!loc) {
      loc = clonedLocs.find(l => {
        return l.idLanguage == defaultLanguage.id;
      });
      if (!loc) {
        return "";
      }
    }
    return typeof loc[fieldName] !== "undefined"
      ? loc[fieldName]
      : "";
  }

  created() {
    this._depsInitialized = 0;

    if (!!localStorageService.getLoggedUser() && !this.$store.state.authStore.currentUser) {
      localStorageService.setLoggedUser(localStorageService.getLoggedUser());
    }
  }

  afterBaseInit() {
    // override this method if needed
  }

  triggerAfterBaseInitIfNeeded() {
    this._depsInitialized += 1;
    if (this._depsInitialized == 2) {
      this.$nextTick(() => this.afterBaseInit());
    }
  }

  afterEventsInit() {
    this.triggerAfterBaseInitIfNeeded();
  }

  afterLanguagesInit() {
    this.triggerAfterBaseInitIfNeeded();
  }
}
