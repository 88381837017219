<template>
  <div class="dropdown-menu">
    <v-menu top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="white"
          class="dropdown-menu__v-btn"
          dark
          v-bind="attrs"
          v-on="on"
        >
          ...
        </v-btn>
      </template>
      <v-list>
        <v-list-item v-for="(item, index) in items" :key="index">
          <v-list-item-title>
            <router-link :to="item.path" class="dropdown-menu__link">
              <span class="dropdown-menu__btn">
                <v-icon>mdi-{{ item.icon }}</v-icon>
                <span class="dropdown-menu__title">{{ item.title }}</span>
              </span>
            </router-link>
          </v-list-item-title>
        </v-list-item>
        <div class="dropdown-menu__link">
          <span class="dropdown-menu__btn-logout" @click="logOut">
            <v-icon>mdi-{{ logout.icon }}</v-icon>
            <span class="dropdown-menu__title">{{ logout.title }}</span>
          </span>
        </div>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import Languages from "@/mixins/languages.js";
import Component, { mixins } from "vue-class-component";
import { localStorageService } from "@/services/localStorageService";

@Component({})
export default class DropdownMenu extends mixins(Languages) {
  items = [
    {
      title: "Home",
      path: "/",
      icon: "home",
    },
    {
      title: "Language",
      path: "/",
      icon: "flag-variant-outline",
    },
    {
      title: "Dati Default",
      path: "/liveSessionDefaultUser",
      icon: "note-text",
    },
  ];

  logout = {
    title: "Log out",
    icon: "arrow-left-circle",
  };

  logOut() {
    localStorageService.removeLoggedUser();
    this.$router.push("/login");
  }
}
</script>

<style lang="scss" scoped>
.dropdown-menu {
  padding-left: 10px;
  &__btn {
    display: flex;
    justify-content: left;
    align-items: center;
    background-color: #fff;
  }

  &__btn:hover {
    transition: transform 0.25s ease-out;
    transform: translate(-2px);
    font-weight: 600;
  }

  &__btn-logout {
    cursor: pointer;
    display: flex;
    justify-content: left;
    align-items: center;
    background-color: #fff;
    margin-left: 18px;
    padding: 5px 0 0 0;
  }

  &__btn-logout:hover {
    transition: transform 0.25s ease-out;
    transform: translate(-2px);
    font-weight: 600;
  }

  &__title {
    margin-left: 10px;
  }

  &__link {
    text-decoration: none;
    color: #000;
    font-size: 12px;
  }

  &__v-btn {
    color: #000 !important;
    font-weight: bold;
  }
}
</style>