export const state = {
  currentLanguage: null,
  languages: [],
};

export const mutations = {
  SET_CURRENT_LANGUAGE(state, currentLanguage) {
    state.currentLanguage = currentLanguage;
  },
  RESET_CURRENT_LANGUAGE(state) {
    state.currentLanguage = null;
  },
  SET_LANGUAGES(state, langs) {
    state.languages = langs;
  },
  RESET_LANGUAGES(state) {
    state.languages = [];
  },
};
