import { storageService } from "@/services/storageService";

class LocalStorageService {
  // Base
  get(name, defaulValue = null) {
    return storageService.getLocal(name, defaulValue);
  }

  set(name, value) {
    storageService.setLocal(name, value);
  }

  remove(name) {
    storageService.removeLocal(name);
  }

  getLoggedUser() {
    return storageService.getLocal("loggedUser");
  }

  setLoggedUser(credentials) {
    storageService.setLocal("loggedUser", credentials);
  }

  removeLoggedUser() {
    storageService.removeLocal("loggedUser");
  }

  getCurrentLang() {
    return storageService.getLocal("currentLang");
  }

  setCurrentLang(lang) {
    storageService.setLocal("currentLang", lang);
  }

  getLanguages() {
    return storageService.getLocal("languages", []);
  }

  setLanguages(langs) {
    return storageService.setLocal("languages", langs);
  }

  removeCurrentLang() {
    storageService.removeLocal("currentLang");
  }

  getCurrentEventId() {
    return storageService.getLocal("currentEventId");
  }

  setCurrentEventId(eventId) {
    storageService.setLocal("currentEventId", eventId);
  }

  removeCurrentEventId() {
    storageService.removeLocal("currentEventId");
  }
}

export const localStorageService = new LocalStorageService();
