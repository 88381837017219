import Vue from "vue";
import Vuex from "vuex";

import * as languageStore from "./languageStore.js";
import * as authStore from "./authStore.js";
import * as eventStore from "./eventStore.js";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    languageStore,
    authStore,
    eventStore,
  }
});
