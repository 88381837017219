<template>
<div>
  <Sidebar/>
<v-app class="v-app-wrapper">
  <!-- <v-app>
    <v-app-bar class="teal darken-1" v-if="showAppbar && isLogged()" app dark>
      <div class="logo d-flex align-center">
        <Logo light />
      </div>

      <v-spacer></v-spacer>

      <ul class="flags">
        <li
          v-for="(language, index) in languages()"
          :key="index"
          class="flag"
          @click="selectLanguage(language, $event)"
        >
          <country-flag
            class="flag-icon"
            :class="isActive(language) ? 'active' : ''"
            :country="language.code"
            size="normal"
          />
        </li>
      </ul>
      <span class="mr-2">
        <router-link to="/liveSessionDefaultUser">
          <p class="live-session-detail-page__back">Default data</p>
        </router-link>
      </span>
      <v-btn>
        <v-icon>mdi-plus-circle</v-icon>
        <span class="mr-2">Aggiungi Evento</span>
      </v-btn>
      <v-btn outlined @click="logout">
        <span class="mr-2">Logout</span>
        <v-icon>mdi-logout-variant</v-icon>
      </v-btn>

    <Navbar />
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>
    <Notification />
  </v-app> -->

      <v-main>
      <router-view />
    </v-main>
</v-app>
</div>
</template>

<script>
import { Utility } from "@/lib/Utility";
import Languages from "@/mixins/languages.js";
import Component, { mixins } from "vue-class-component";
import { localStorageService } from "@/services/localStorageService.js";
import Logo from "@/components/logo";
import CountryFlag from "vue-country-flag";
import Notification from "@/components/dom/notification";
import Navbar from "@/components/navbar/Navbar";
import Sidebar from "@/components/dom/sidebar";

@Component({
  name: "App",
  components: {
    Logo,
    CountryFlag,
    Notification,
    Navbar,
    Sidebar
  },
})
export default class App extends mixins(Languages) {

  get showAppbar() {
    return this.$route.meta?.showAppbar;
  }

  recomputeFlags() {
    this.recomputeFlagId = Utility.uid("ab");
  }

  isLogged() {
    return !!localStorageService.getLoggedUser();
  }

  selectLanguage(language, $event) {
    $event.preventDefault();
    this.setCurrLang(language);
  }

  isActive(language) {
    const lang = this.currLang();
    return language.id === lang?.id;
  }
}
</script>

<style lang="scss" scoped>
.v-app-wrapper{
  padding-left: 50px;
}
// .logo {
//   width: 200px;
// }
// .flags {
//   margin-top: 10px;
//   margin-right: 25px;
//   list-style-type: none;
//   padding: 0;
//   display: inline-block;
// }
// .flag {
//   display: inline-block;
// }
// .flag-icon {
//   cursor: pointer;
//   position: relative;
//   border: none;
//   display: inline-block;
//   z-index: 1;
//   opacity: 0.7;
//   background-color: rgba(255, 255, 255, 0.5);

//   &:before {
//     content: "";
//     position: absolute;
//     top: -8px;
//     bottom: -8px;
//     left: -8px;
//     right: -8px;
//     border-radius: 2px;
//     z-index: -1;
//   }

//   &.active {
//     opacity: 1;
//     &:before {
//       border: 8px solid rgba(255, 255, 255, 0.5);
//     }
//   }

//   &:hover {
//     opacity: 1;
//     &:not(.active):before {
//       border: 8px solid rgba(255, 255, 255, 0.25);
//     }
//   }
//}
</style>