<template>
  <div class="notification" v-if="isVisible">
    <h6 class="notification__error">{{ _error }}</h6>
    <h6 class="notification__success">{{ _message }}</h6>
  </div>
</template>

<script>
import Base from "@/mixins/base.js";
import { eventBus } from "@/lib/EventBus";
import Component from "vue-class-component";


@Component()
export default class Notification extends Base {
  error = "";
  message = "";
  isVisible = false;

  get _error(){
    return this.error;
  }

  get _message(){
    return this.message;
  }

  handleSuccess(message) {
    this.message = message;
    this.isVisible = true;

    setTimeout(()=>{
    this.isVisible = false;
    }, 3000);
  }

  handleFail(error) {
    this.error = error;
    this.isVisible = true;

    setTimeout(()=>{
    this.isVisible = false;
    }, 3000);
  }

  mounted() {
    eventBus.$on("notifySuccess", this.handleSuccess);
    eventBus.$on("notifyFail", this.handleFail);
  }

  destroyed() {
    eventBus.$off("notifySuccess");
    eventBus.$off("notifyFail");
  }
}
</script>

<style lang="scss" scoped>
.notification {
    background-color: #FFA500;
    height: 100px;
    z-index: 1000;
    position: absolute;
    transform: translate(-50%);
    left: 50%;
    top: 50%;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    padding: 15px;

  &__error {
    font-size: 18px;
    color: #fff;
  }

  &__success {
    font-size: 18px;
    color: #fff;
  }
}
</style>
