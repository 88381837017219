import { localStorageService } from "@/services/localStorageService.js";
import { HTTPError } from "@/lib/errors";
import _env from "@/plugins/env";

export class ApiHandler {
  constructor(_endPoint) {
    this.endPoint =
      _env("VUE_APP_API_BASE_URL").trimEnd("/") +
      _endPoint.trimEnd("/") +
      "/";
  }

  async _fetch(endpoint, options) {
    const resp = await fetch(endpoint, options);
    if (resp.ok || typeof window === "undefined") {
      return resp;
    }
    const code = resp.status;
    const statusText = resp.statusText;
    let body = null;
    try {
      body = await resp.json();
    } catch (err) {
      // no json body
      body = {};
    }
    throw new HTTPError(code, statusText, body?.Error?.Message ?? null, {
      endpoint: endpoint.replace(this.endPoint, "")
    });
  }

  async get(suffix = "", data = {}, extraConfig = {}) {
    const queryString = queryStringBuilder(data);
    return await this._fetch(
      this.endPoint + suffix + queryString,
      getConfig({ method: "GET", ...extraConfig })
    );
  }

  async post(suffix = "", body = {}, extraConfig = {}) {
    return await this._fetch(
      this.endPoint + suffix,
      getConfig({ method: "POST", body: JSON.stringify(body), ...extraConfig })
    );
  }

  async put(suffix = "", body = {}, extraConfig = {}) {
    return await this._fetch(
      this.endPoint + suffix,
      getConfig({ method: "PUT", body: JSON.stringify(body), ...extraConfig })
    );
  }

  async delete(suffix = "", data = {}, extraConfig = {}) {
    const queryString = queryStringBuilder(data);
    return await this._fetch(
      this.endPoint + suffix + queryString,
      getConfig({ method: "DELETE", ...extraConfig })
    );
  }

  async patch(suffix = "", body = {}, extraConfig = {}) {
    return await fetch(
      this.endPoint + suffix,
      getConfig({ method: "PATCH", body: JSON.stringify(body), ...extraConfig })
    );
  }

  head() {
    // head
  }

  //   /api/users/{idUser}/accept-business-data
  // parametri: idUser in query
  // 		httppatch
}

function getHeaders() {
  const user = localStorageService.getLoggedUser();
  const headers = {
    "Accept-Language": localStorageService.getCurrentLang() ?? "",
    "X-Event-ID": localStorageService.getCurrentEventId() ?? "",
    "Content-type": "application/json",
    Accept: "application/json"
  };
  if (user) {
    headers.Authorization = `Bearer ${user.jwtToken}`;
  }
  return headers;
}

function getConfig(opts) {
  return {
    mode: "cors",
    headers: getHeaders(),
    redirect: "follow",
    ...opts
  };
}

function queryStringBuilder(params) {
  if (!params) {
    return "";
  }

  const queryStringFragment = Object.keys(params)
    .map(key => key + "=" + params[key])
    .join("&");
  return queryStringFragment ? "?" + queryStringFragment : "";
}
