import Vue from "vue";
import Component from "vue-class-component";
import { eventService } from "@/services/eventService.js";

@Component()
export default class Events extends Vue {
  // computed

  // methods

  events () {
    return this.$store.state.eventStore?.events ?? [];
  }

  event(eventId) {
    const events = this.events();
    if (events === null || events?.length === 0) {
      return null;
    }
    if (events === null) {
      return null;
    }
    const event = events.filter(e => {
      return e.id == eventId;
    });
    return event.length > 0
      ? event[0]
      : null;
  }

  eventField(eventId, fieldName) {
    const event = this.event(eventId);
    if (!event || typeof event[fieldName] === "undefined") {
      return "";
    }
    return event[fieldName];
  }

  afterEventsInit() {
    // override this method if needed
  }

  isEventsInitialized () {
    return !(this.events() === null || typeof this.events() === "undefined" || this.events().length === 0);
  }

  async mounted () {
    // if the store is already set, we avoid to re-fetch configs, languages, etc.
    // if even one of them is missing, we "refresh" all of them, just to be sure.
    if (this.isEventsInitialized() || Events.prototype._initializing === true) {
      this.afterEventsInit();
      return;
    }
    Events.prototype._initializing = true;
    const events = await eventService.list();
    this.$store.commit("SET_EVENTS", events);
    this.afterEventsInit();
  }
}
