import { ApiHandler } from "@/lib/ApiHandler";

class EventService extends ApiHandler {
  constructor() {
    super("/api/events");
  }

  async list() {
    return await this.get()
      .then(res => res.json())
      .then(res => res.result);
  }
}

export const eventService = new EventService();
